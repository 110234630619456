<template>
  <v-btn
    color="info"
    v-show="billPlan === 'essential'"
    small
    link
    elevation="0"
    href="/fr/tarifs"
    target="_blank"
    >{{ label }}<v-icon right small
      >mdi-open-in-new</v-icon
    ></v-btn
  >
</template>

<script>
export default {
  name: "BillPlanRequired",
  props: {
    billPlan: String,
    label: {
      type: String,
      default: "Disponible avec l'offre Pro"
    }
  },
};
</script>