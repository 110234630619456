<template>
  <div>
    <!-- <v-banner v-if="billPlan == 'essential'">Page disponible pour l'offre "Pro"</v-banner> -->
    <div class="text-right mr-2">
      <bill-plan-required
        :billPlan="billPlan"
        label="Page disponible avec l'offre Pro"
      />
    </div>
    <router-view v-if="organization" />
    <div v-else>
      <v-container fluid class="ma-10">
        <v-row align="center" justify="center">
          <v-progress-circular
            :size="100"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import PublicOrganizationService from "@/services/publicorganizationservice";
import BillPlanRequired from "@/components/Admin/BillPlanRequired";

export default {
  components: {
    BillPlanRequired,
  },
  data() {
    return {
      organization: null,
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
    billPlan() {
      return this.organization?.billPlan;
    },
  },
  async mounted() {
    // const facebookChat = document.querySelector(".fb_dialog");

    // if (facebookChat) {
    //   facebookChat.style.display = "none";
    // }

    try {
      const organization = await PublicOrganizationService.get(
        this.organizationUrl
      );

      this.organization = organization.data;
    } catch (error) {
      console.dir(error);
    }
  },
  methods: {},
  metaInfo() {
    return {
      title: "monplanning",
      titleTemplate: this.$t("meta.public-organization-title", {
        organizationName: this.organization?.name,
      }),
    };
  },
};
</script>